<script lang="ts" setup>
defineProps<{
  items: (NavigationItem | NavigationDivider)[]
}>()

const route = useRoute()

const { getColorValue } = useColor()

const isActiveItem = (item: NavigationItem): boolean => {
  if (item.route.includes('?')) {
    return route.fullPath === item.route
  } else {
    return route.path === item.route
  }
}

const isNavItem = (item: NavigationItem | NavigationDivider): item is NavigationItem => !item.divider
</script>

<template>
  <div>
    <nav class="nav">
      <div v-for="(item, i) in items" :key="`${item}-${i}`" data-cy="sub_navigation">
        <v-btn
          v-if="isNavItem(item)"
          :id="item.identifier"
          class="nav__link"
          variant="plain"
          rounded
          :class="{
            'nav__link--active': isActiveItem(item),
          }"
          :style="{ '--active-color': getColorValue(item.color ?? 'primary') }"
          :ripple="false"
          :data-cy="`sub_navigation_item_${i}`"
          :to="item.route"
        >
          <slot name="content" :item="item">
            {{ item.title }}
            <v-icon>{{ item.icon }}</v-icon>
          </slot>
        </v-btn>
        <v-divider v-else class="my-2 mx-4" />
      </div>
    </nav>
  </div>
</template>

<style lang="sass" scoped>
.nav
  display: flex
  flex-direction: column
  min-width: 230px

  &__link
    width: 100%
    height: 44px !important
    margin: 0
    font-weight: bold
    font-size: 1rem !important
    cursor: pointer
    justify-content: unset
    opacity: 1
    &--active
      background-color: color-mix(in srgb, var(--active-color), white 90%)
      color: var(--active-color)
    &:hover
      color: var(--active-color)
    :deep(.v-btn__content)
      display: flex
      justify-content: space-between
      align-items: center
      opacity: 1 !important
</style>
