export default function useColor() {
  const theme = useTheme()

  const getColorValue = (color: string): string =>
    theme.current.value.colors[color] ?? theme.current.value.colors.primary

  return {
    getColorValue,
  }
}
